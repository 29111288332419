<template>
  <v-dialog v-model="settingsDialog" max-width="800">
    <dialog-card title="Configurar Rolagem Automática" @close="closeDialog">
      <div class="d-flex flex-column align-center mt-5">
        <span>Selecione a velocidade</span>
        <v-btn-toggle v-model="selectedSpeed" mandatory>
          <v-btn :value="0.5"> 0.5x </v-btn>
          <v-btn :value="1"> 1x </v-btn>
          <v-btn :value="1.5"> 1.5x </v-btn>
          <v-btn :value="2"> 2x </v-btn>
        </v-btn-toggle>
      </div>
      <div class="text-center mt-10">
        <v-btn color="secondary" large @click.stop="backAndStart">
          Voltar e iniciar
        </v-btn>
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AutoScrollSettingsDialog",

  props: {
    handleStart: {
      type: Function,
      required: true,
    },
  },
  computed: {
    selectedSpeed: {
      get() {
        return this.$store.state.autoScroll.selectedSpeed;
      },
      set(value) {
        this.$store.commit("autoScroll/setSelectedSpeed", value);
      },
    },
    settingsDialog: {
      get() {
        return this.$store.state.autoScroll.settingsDialog;
      },
      set(value) {
        this.$store.commit("autoScroll/setSettingsDialog", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.settingsDialog = false;
    },
    backAndStart() {
      this.settingsDialog = false;
      this.$nextTick(() => {
        this.handleStart();
      });
    },
  },
};
</script>

<style scoped></style>
